.modal
  position: fixed
  backface-visibility: hidden
  display: block
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow-y: auto
  background: rgba($c-text, 0.5)
  transition: $trs-dur
  transition-property: opacity
  opacity: 0
  z-index: 1010
  visibility: hidden
  padding: 15px 15px 0 15px
  +r($md)
    padding: 0
  +custom-scrollbar
  // &_indent_header
  //   +rmin($md)
  //     // CSS vars fallback
  //     top: 0
  //     height: 100%
  //     top: var(--main-header-height)
  //     z-index: 900
  //     height: calc(100% - var(--main-header-height))
  &:not(.modal_active)
    .button,
    button,
    a
      transition: none
  &_active
    opacity: 1
    visibility: visible
    .modal__content
      transform: none
  &__box
    width: 100%
    min-height: 100%
    position: relative
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    &::after
      content: ""
      display: block
      height: 15px
      width: 100%
      background: transparent
      +r($md)
        display: none
  &__content
    position: relative
    display: flex
    flex-direction: column
    margin: auto
    background-color: #ffffff
    transition: $trs-dur
    transform: translateY(50px)
    max-width: 100%

.default-modal
  display: flex
  flex-direction: column
  background-color: #ffffff
  width: 790px
  max-width: 100%
  +r($md)
    width: 100%
    flex: 1
    border-radius: 0
  &__box
    padding: 40rem
    margin: auto
    +r($md)
      padding: 50rem 15rem
  &__header
    display: flex
    align-items: center
  &__title
    margin-bottom: 15rem
  &__close
    display: block
    margin: 20rem 20rem 20rem auto
    color: $c-text
    &:hover
      color: $c-red
  &__close-icon
    font-size: 25px
  &__actions
    margin-top: 45rem
    margin-right: -15rem
    margin-bottom: -15rem
  &__actions-btn
    margin-right: 15rem
    margin-bottom: 15rem
