.main-menu
  font-size: 18rem
  font-weight: 300
  +r($xxl)
    font-size: 16rem
  +r($lg)
    font-size: 20px
    text-align: left
    padding: 0
    z-index: -1
    cursor: pointer
    top: 0
    right: 0
    left: 0
    user-select: none
    min-height: auto
    box-shadow: none
    padding: 0 
    transform: scale3d(1, 0, 0)
    transform-origin: 0 0
    position: fixed
    &.active
      transition: 0.3s all ease
      top: 50px
      bottom: 0
      right: 0
      left: 0
      padding: 20px
      transform: scale3d(1, 1, 1)
      background: $c-blue
  &__inner
    display: flex
    align-items: center
    +rmin($lg)
      position: relative
    +r($lg)
      flex-direction: column
      overflow-y: auto
      height: auto
      max-height: 100%
  &__list
    display: flex
    align-items: center
    color: $c-text
    +r($lg)
      color: $c-accent
      order: 2
      width: 100%
      max-width: 755px
      user-select: none
      z-index: 100
      list-style-type: none
      margin-left: 0
      padding: 0 15px
      visibility: hidden
      display: block
      transform: scale(1, 0)
      // vertical top start
      transform-origin: 50% 0
      transition: 0.4s ease
      &.active
        visibility: visible
        transform: scale(1, 1)
    +r($md)
      max-width: 570px
  &__item
    position: relative
    margin-right: 25rem
    margin-left: 25rem
    +r($xxl)
      margin-right: 15rem
      margin-left: 15rem
    +r($xl)
      margin-right: 10rem
      margin-left: 10rem
      .button
        padding: 8rem 15rem
        font-size: 15rem
    +r($lg)
      margin: 0
      padding: 0
      .button
        border-radius: 0
        width: 100%
        height: auto
        padding: 15rem 0 
        color: $c-blue
        font-size: 20px
        font-weight: 500
    &:last-child
      margin-right: 0
    +r($lg)
      &.active
        .main-menu__link
          color: $c-accent
  &__link
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    position: relative
    &:hover
      color: $c-accent
    &.active
      color: $c-accent
    +r($lg)
      height: 100%
      width: 100%
      padding: 12px 15px
      display: inline-flex
      border-bottom: 1px solid $c-accent
      &:hover
        background-color: $c-accent
        color: $c-blue
        
    &--icon
      font-size: 30rem
      +r($xxl)
        font-size: 22rem
      +r($lg)
        font-size: 22px
    
    


.menu-opener
  position: relative
  display: block
  transition: 0.3s
  --color: #{$c-text}
  cursor: default
  &.active
    .menu-opener
      &__lines
        background-color: transparent
        &::before
          transform: rotate(45deg)
        &::after
          transform: rotate(-45deg)
  &__inner
    display: block
    position: relative
    cursor: pointer
    z-index: 1002
    width: 100%
    height: 100%
    padding: 23rem 0 23rem 21rem
    transition: 0.3s
  &__lines
    display: block
    position: relative
    width: 23rem
    height: 2px
    background-color: var(--color)
    border-radius: 9px
    transition: 0.3s
    &::before,
    &::after
      content: ""
      position: absolute
      left: 0
      top: 0
      height: 100%
      width: 100%
      background-color: var(--color)
      border-radius: 9px
      transition: 0.3s
    &::before
      transform: translateY(-8px)
      +r($md)
        transform: translateY(-6px)
    &::after
      transform: translateY(8px)
      +r($md)
        transform: translateY(6px)
