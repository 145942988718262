.hero
    width: 100%
    height: 680rem
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    +r($xl)
        height: 550rem
    +r($md)
        height: auto
    .container
        display: flex
        height: 100%
        +r($lg)
            align-items: flex-end
        +r($md)
            flex-wrap: wrap
    &__left
        width: 650px
        height: 100%
        display: flex
        align-items: center
        flex-wrap: wrap
        +r($xxl)
            width: 500px
        +r($xl)
            width: 430px
        +r($md)
            width: 100%
            padding-top: 50px
            padding-bottom: 50px
    &__title
        color: $c-blue
        span
            color: #fff
    &__text
        margin-top: 2em
        font-size: 22px
        +r($xl)
            font-size: 18px
        +r($md)
            font-size: 16px
    &__right
        width: calc(100% - 650px)
        text-align: right
        padding-top: 50rem
        +r($xxl)
            width: calc(100% - 500px)
        +r($xl)
            width: calc(100% - 430px)
        +r($md)
            width: 100%
            display: none
        
    &--join
        background-color: #f2f2f2
        height: 900px
        +r($md)
            height: auto
        .container
            align-items: center
        .hero__title
            span
                color: $c-accent
        a
            text-decoration: none
        img
            max-width: 650px
            +r($xxl)
                max-width: 95%
    
    &--company
        background-color: #f2f2f2
        height: 600px
        .hero__left


.slogan
    width: 100%
    height: 745rem
    margin-top: 160rem
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    position: relative
    +r($xl)
        height: 600rem
        margin-top: 100rem
    +r($lg)
        height: 500rem
        br
            display: none
    +r($md)
        margin-top: 0
    &::after
        content: ""
        display: block
        width: 100%
        height: 100%
        position: absolute
        z-index: 1
        top: 0
        left: 0
        background-color: #00033f9a
    .container
        position: relative
        z-index: 2
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        text-align: center
    &__title
        color: $c-accent
        margin-bottom: 1.5em
    &__text
        font-size: 25rem
        font-weight: 500
        color: #fff

.infinity-vault
    padding-top: 120rem
    padding-bottom: 120rem
    text-align: center
    &-content
        display: flex
        justify-content: space-between
        padding-top: 60rem
        +r($lg)
            flex-wrap: wrap
            justify-content: center
    &__left
        width: 675px
        text-align: center
        +r($xxl)
            width: 600px
        +r($xl)
            width: 430px
            img
                max-width: 550px
                margin-left: -70rem
        +r($lg)
            img
                max-width: 100%
                margin-left: 0
    &__right
        width: calc(100% - 725px)
        text-align: left
        padding-top: 20rem
        +r($xxl)
            width: calc(100% - 625px)
        +r($xl)
            width: calc(100% - 430px)
        +r($lg)
            width: 100%
            text-align: center
    .standard-yield
        width: 90px
        margin-left: 50px
        margin-top: 35rem
        margin-bottom: 35rem
        +r($lg)
            display: inline-block
            margin-left: 0
    .premium-yield
        width: 120px
        margin-left: 50px
        margin-top: 35rem
        margin-bottom: 35rem
        +r($lg)
            display: inline-block
            margin-left: 0
    .plaintext
        font-size: 22rem
        +r($xxl)
            font-size: 18rem

.video
    width: 100%
    height: 700px
    +r($xl)
        height: 65vw
    iframe
        width: 100%
        height: 100%
        max-height: 60vw

.cashets
    padding-top: 200rem
    padding-bottom: 125rem
    text-align: center
    +r($xl)
        padding-top: 80rem
        padding-bottom: 75rem
    &__boxes
        display: flex
        align-items: center
        justify-content: space-between
        margin-top: 100rem
        +r($lg)
            flex-wrap: wrap
        +r($md)
            margin-top: 50rem
    &__box-item
        width: 450px
        height: 420px
        max-width: 32%
        border-radius: 15px
        box-shadow: 0 0 10px 6px rgba(0,0,0,0.10)
        transition: all .35s linear
        padding-left: 15px
        padding-right: 15px
        &:hover
            transform: scale(1.05)
        +r($xxl)
            height: 400px
        +r($lg)
            max-width: 100%
            margin: 25rem auto
        +r($md)
            height: auto
            padding-bottom: 15px

    &__box-icon
        width: 100%
        height: 235px
        text-align: center
        display: block
        padding-top: 25rem
        +r($xxl)
            height: 200px
        +r($md)
            height: 130px
        &:hover
            opacity: .75
        &-1
            img
                max-width: 200px
                margin-left: 25px
                +r($xxl)
                    max-width: 160px
                +r($md)
                    max-width: 110px
        &-2
            img
                max-width: 160px
                margin-top: 40px
                +r($xxl)
                    max-width: 125px
                    margin-top: 30px
                +r($md)
                    max-width: 85px
                    margin-top: 15px
        &-3
            img
                max-width: 180px
                margin-top: 40px
                +r($xxl)
                    max-width: 145px
                    margin-top: 30px
                +r($md)
                    max-width: 95px
                    margin-top: 15px

    &__box-title
        font-size: 32px
        font-weight: 500
        color: $c-blue
        margin-top: 25rem
        &:hover
            color: $c-blue2
        +r($xxl)
            font-size: 25rem
        +r($xl)
            font-size: 22rem
        +r($md)
            font-size: 20px
            br
                display: none
    &__box-text
        margin-top: 25rem
        font-size: 18rem
        font-weight: 400


.how-it-works 
    padding: 80rem 0
    background: rgb(223,224,226)
    background: linear-gradient(0deg, rgba(223,224,226,1) 0%, rgba(255,255,255,1) 35%)
    text-align: center
    .plaintext
        font-size: 20rem
        max-width: 1100px
        margin: auto
        margin-top: 50rem
        a
            font-weight: 500
            text-decoration: none

.how-it-works button:hover 
    transform: scale(1.1)    

.stepsProgress-inside 
    position: absolute
    width: 0
    left: 4px
    top: calc(50% - 2rem)
    height: 32px
    transition: width 2.5s linear
    +r($md)
        top: calc(50% + 2px)
        left: 2px

#stepsProgress
    background-image: url("../img/step-bar-container.svg")
    background-size: 90% 60px
    background-repeat: no-repeat
    width: 100%
    max-width: 1250px
    margin: 32rem auto
    padding: 112rem 0
    overflow: hidden
    position: relative
    background-position-y: 96rem
    &.aos-animate
        .stepsProgress-inside 
            width: calc(90% - 12px)
            height: 32px
            +r($md)
                width: calc(90% - 6px)
            
    .stepBar-text-30 
        top: 75%
        position: absolute
        left: 6.5%
        font-size: 18rem
        font-weight: 500
        +r($md)
            left: 1%

    .stepBar-text-90 
        top: 75%
        position: absolute
        left: 26%
        font-size: 18rem
        font-weight: 500
        +r($md)
            left: 20%

    .stepBar-text-180 
        top: 75%
        position: absolute
        left: 56%
        font-size: 18rem
        font-weight: 500
        +r($md)
            left: 40%

    .stepBar-text-365 
        top: 75%
        position: absolute
        left: 85%
        white-space: nowrap
        font-size: 18rem
        font-weight: 500
        +r($md)
            left: 80%

    .stepBar-text-365 small 
        display: block
        font-size: 13rem
        font-weight: 400

    .stepBar-icon-30 
        max-width: 20rem
        bottom: 60%
        position: absolute
        left: 8%
        +r($md)
            left: 3%

    .stepBar-icon-90 
        max-width: 22rem
        bottom: 60%
        position: absolute
        left: 27%
        +r($md)
            left: 22%

    .stepBar-icon-180 
        max-width: 48rem
        bottom: 60%
        position: absolute
        left: 57%
        +r($md)
            left: 42%

    .stepBar-icon-365 
        max-width: 85rem
        bottom: 40%
        position: absolute
        left: 91%
        +r($lg)
            left: 85%
            bottom: 55%
        +r($md)
            left: 80%
    
    span
        +r($md)
            font-weight: 300!important
            font-size: 12px!important
    
.tokens
    padding-top: 170rem
    padding-bottom: 100rem
    text-align: center
    +r($lg)
        padding-top: 120rem
        padding-bottom: 80rem
        br
            display: none
    &__area
        position: relative
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        max-width: 1130px
        height: 1100px
        margin-top: 75rem
        margin-left: auto
        margin-right: auto
        +r($md)
            height: auto
            flex-wrap: wrap
    .token
        &__symbol
            width: 235px
            height: 235px
            +r($xl)
                width: 200px
                height: 200px
            img
                max-height: 100%
                max-width: 100%
            +r($md)
                order: -1
        &__benefit
            position: absolute
            width: 320px
            text-align: center
            +r($lg)
                width: 250px
            +r($md)
                position: static
                margin-top: 30px
                width: 100%
            &__image
                width: 130px
                height: 125px
                margin: auto
                display: flex
                align-items: center
                justify-content: center
                +r($lg)
                    width: 110px
                    height: 105px
            &__title
                font-size: 21rem
                font-weight: 700
                color: $c-blue
                margin-top: 40rem
                +r($lg)
                    font-size: 18rem
                +r($md)
                    font-size: 18px
            &__text
                font-size: 18rem
                margin-top: 20rem
                +r($lg)
                    font-size: 15rem
                +r($md)
                    font-size: 16px
            &:nth-child(1)
                top: 0
            &:nth-child(2)
                top: 195px
                right: 0
            &:nth-child(3)
                bottom: 195px
                right: 0
            &:nth-child(4)
                bottom: 0
            &:nth-child(5)
                bottom: 195px
                left: 0
            &:nth-child(6)
                top: 195px
                left: 0


.yields
    padding-top: 200rem
    background-image: linear-gradient(180deg, rgba(113,125,124,0.65) 0%, rgba(255,255,255,0) 250px)
    +r($md)
        padding-top: 100rem
    &-title-mobile
        display: none
        +r($md)
            display: block
            margin-bottom: 25rem
            text-align: center
    &__items
        display: flex
        justify-content: space-between
        max-width: 1150px
        width: 100%
        margin: auto
        padding-left: 15px
        padding-right: 15px
        +r($md)
            flex-wrap: wrap
            max-width: 500px
    &__item
        width: 25%
        &__title
            min-height: 170rem
        &__row
            height: 50rem
            display: flex
            align-items: center
            justify-content: center
            font-size: 18rem
            width: 100%
            border-bottom: 1px solid $c-border
            text-align: center
            +r($lg)
                font-size: 15rem
            +r($md)
                font-size: 14px
                justify-content: space-between
                padding-left: 15px
                padding-right: 15px
            &.no-border
                border-bottom: none
            &.highlighted
                color: $c-accent
                font-weight: 700
            &.inactive
                position: relative
                &::after
                    content: "X"
                    width: 18px
                    height: 18px
                    background-color: $c-border
                    text-align: center
                    padding-top: 3px
                    font-size: 12px
                    line-height: 1
                    color: #fff
                    font-weight: 700
                    border-radius: 50%
            &.active
                position: relative
                &::after
                    content: ""
                    width: 18px
                    height: 18px
                    background-color: $c-accent
                    text-align: center
                    padding-top: 3px
                    font-size: 12px
                    line-height: 1
                    color: #fff
                    font-weight: 700
                    border-radius: 50%
                &::before
                    content: ""
                    display: inline-block
                    transform: rotate(45deg)
                    height: 10px
                    width: 5px
                    border-bottom: 2px solid #fff
                    border-right: 2px solid #fff
                    position: relative
                    top: -1px
                    left: 11px                   
                    +r($md)
                        position: absolute
                        top: 13px
                        right: 21px
        &__name
            width: 100%
            font-size: 21rem
            font-weight: 500
            text-align: center
            height: 90rem
            display: flex
            align-items: center
            justify-content: center
            +r($lg)
                font-size: 18rem
                line-height: 1.2
            +r($md)
                font-size: 22px
        &__price
            font-size: 35px
            color: $c-blue
            font-weight: 500
            width: 100%
            height: 70rem
            display: flex
            align-items: center
            justify-content: center
            flex-wrap: wrap
            text-align: center
            sub
                font-size: 15rem
                bottom: -.4em
            span
                font-size: 15rem
                display: block
                width: 100%
                line-height: 0.2
            +r($lg)
                font-size: 25rem
                sub, span
                    font-size: 13rem
            +r($md)
                font-size: 25px
        &__mobile
            font-weight: 300
            color: $c-text
            display: none
            +r($md)
                display: block
        &--first
            width: 400px
            .yields__item__row
                padding-left: 15px
                text-align: left
                justify-content: flex-start
            +r($lg)
                width: 300px
            +r($md)
                display: none
        &--package
            text-align: center
            padding-bottom: 100px
            
            .button
                margin-top: 75rem
                // opacity: 0
                // visibility: hidden
                // z-index: -1
                // transition: all .25s linear
                +r($md)
                    margin-top: 40rem
        &.most-popular
            position: relative
            &::before
                content: "MOST POPULAR"
                position: absolute
                width: 100%
                max-width: 180px
                height: 40px
                top: -40px
                left: calc((100% - 180px)/2)
                border-radius: 8px 8px 0 0
                background-image: linear-gradient(90deg, #ffb630 0%, #ffc94a 100%)
                color: #fff
                font-size: 15rem
                font-weight: 500
                display: flex
                align-items: center
                justify-content: center
                +r($md)
                    max-width: 100%
                    left: 0
            &::after
                content: ""
                position: absolute
                display: block
                width: 100%
                max-width: 180px
                height: calc(100% - 200px)
                top: 0
                left: calc((100% - 180px)/2)
                background-color: $c-accent
                opacity: .1
                +r($md)
                    max-width: 100%
                    left: 0
                    height: calc(100% - 175px)
                    z-index: -1
        +r($md)
            width: 100%
        

.refer-friend
    padding-top: 120rem
    padding-bottom: 120rem
    border-top: 1px solid $c-border
    text-align: center
    .title-5
        margin-top: 25rem
    .title-4
        margin-top: 60rem
        font-weight: 700
    &__steps
        display: flex
        width: 100%
        justify-content: space-between
        +r($md)
            flex-wrap: wrap
    &__step
        &-item
            width: 360px
            text-align: center
            margin-top: 50rem
            position: relative
            &-arrow
                position: relative
                display: block
                width: 135px
                height: 55px
                top: 100px
                background-image: url(../img/svg/graphic/step-arrow.svg)
                +r($md)
                    display: none
            +r($md)
                width: 100%
            
        &-image
            width: 140px
            height: 140px
            margin: auto
            img
                max-height: 100%
                max-width: 100%
            +r($lg)
                width: 100rem
                height: 100rem
            +r($md)
                width: 100px
                height: 100px
        &-title
            font-size: 32px
            color: $c-blue
            font-weight: 500
            margin-top: 40rem
            +r($lg)
                font-size: 24rem
            +r($md)
                font-size: 20px
        &-text
            font-size: 21rem
            margin-top: 25rem
            line-height: 1.5
            +r($xxl)
                font-size: 18rem
            +r($xl)
                br
                    display: none
            +r($lg)
                font-size: 16rem
            +r($md)
                font-size: 16px

.join-community
    height: 710px
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    display: flex
    align-items: center
    justify-content: center
    padding-left: 15px
    padding-right: 15px
    margin-bottom: 130rem
    &__content
        
        text-align: center
        color: #fff
        .title-4
            font-weight: 700
        .plaintext
            font-size: 21rem
            margin-top: 25rem
            +r($lg)
                font-size: 18rem
    +r($lg)
        height: 600rem
        br
            display: none


.friends-deal
    padding-top: 150rem
    padding-bottom: 100rem
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    +r($lg)
        padding-top: 75rem
        padding-bottom: 75rem
    &__left
        width: 42%
        position: relative
        +r($xl)
            width: 46%
        +r($lg)
            width: 100%
        &.plus
            &::after
                content: "+"
                text-shadow: .03em .03em #e2e2e2
                font-size: 80rem
                font-weight: 700
                position: absolute
                top: 90%
                right: -22%
                color: $c-blue
                +r($lg)
                    display: none
    &__right
        width: 42%
        +r($xl)
            width: 46%
        +r($lg)
            width: 100%
    &__percent
        color: $c-accent
        text-shadow: .03em .03em #e2e2e2
        font-size: 100px
        font-weight: 700
        +r($xxl)
            font-size: 70px
        +r($xl)
            font-size: 60px
        +r($lg)
            font-size: 50rem
    &__title
        color: $c-blue
        span
            color: $c-accent
    &__text
        font-size: 21px
        padding-top: 50rem
        +r($xxl)
            font-size: 18px
        +r($md)
            font-size: 16px
    &__numbers
        height: 380px
        position: relative
        display: flex
        align-items: center
        padding-left: 120px
        +r($xl)
            padding-left: 0
        +r($lg)
            width: 100%
            justify-content: center
            text-align: center
            height: 300rem
    &__content
        +r($lg)
    &__coin
        width: 110px
        height: 110px
        position: absolute
        background-image: url(../img/svg/graphic/coin.svg)
        background-size: 100%
        left: 0
        top: 50%
        +r($xl)
            width: 75rem
            height: 75rem
            top: auto
            bottom: 15%
        &--2
            background-image: url(../img/svg/graphic/coin-euro.svg)
            width: 70rem
            height: 70rem
            top: 5%
            left: 25%
            +r($xl)
                width: 40rem
                height: 40rem
                top: 25%
                left: 15%
        &--3
            width: 90rem
            height: 90rem
            top: 10%
            right: 10%
            left: auto
            +r($xl)
                width: 55rem
                height: 55rem
                top: 25%
    +r($lg)
        &--a
            order: 1
        &--b
            order: 2
        &--c
            order: 4
        &--d
            order: 3


.how-to-use
    padding-top: 150rem
    padding-bottom: 100rem
    background-image: linear-gradient(180deg, rgba(113,125,124,0.45) 0%, rgba(255,255,255,0) 150px)

    +r($xl)
        padding-top: 100rem
        padding-bottom: 75rem
    &__steps
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        padding-top: 150rem
    .htw-step
        display: block
        position: relative
        box-shadow: 0 0 15px 10px rgb(0 0 0 / 10%)
        padding: 100rem 15rem 30rem
        transition: all .35s linear
        border-radius: 15px
        width: 22.5%
        margin-top: 40rem
        margin-bottom: 40rem
        +r($xxl)
            width: 48%
        +r($xl)
            width: 100%
            br
                display: none            
        &:hover
            transform: scale(1.05)
        &__no
            background-color: #fff
            width: 80rem
            height: 80rem
            box-shadow: 0 0 4px 3px rgb(0 0 0 / 15%)
            border-radius: 50%
            display: flex
            align-items: center
            justify-content: center
            font-size: 55rem
            font-weight: 700
            color: $c-accent
            position: absolute
            left: calc(50% - 40rem)
            top: -40rem
        &__title
            color: $c-blue
            text-align: center
        &__text
            margin-top: 30rem
            font-size: 22rem
            text-align: center

.links
    padding-top: 75rem
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    padding-bottom: 75rem
    &__content
        padding-top: 20rem
    &__left
        width: 48%
        +r($lg)
            width: 100%
            
    &__right
        width: 48%
        display: flex
        align-items: center
        justify-content: center
        img
            max-width: 80%
            text-align: center
        +r($lg)
            width: 100%
            margin-top: 50rem
    .title-5
        margin-bottom: 20rem