.embedded-video
  &__poster
    position: absolute
    z-index: 1
    top: 0
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    border: 0
    object-fit: cover
    overflow: hidden
    transition: 0.3s
    &::after
      content: ""
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
    &_filter_darkened
      &::after
        background-color: rgba(#000000, 0.18)
    &_filter_dark-gradient
      &::after
        background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0.01) 99%, rgba(0, 0, 0, 0) 100%)
  &__play
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    z-index: 1
    transition: 0.3s
    &:hover
      .embedded-video__play-img
        transform: scale(1.2)
  &__play-img
    display: block
    width: 100rem
    height: 100rem
    transition: 0.3s
    border-radius: 50%
    box-shadow: 0 3px 10px rgba(91, 196, 191, 0.51)
    position: relative
    +r($sm)
      width: 70rem
      height: 70rem
  &_state_play
    .embedded-video
      &__poster
        opacity: 0
        visibility: hidden
      &__play
        opacity: 0
        visibility: hidden
      &__descr
        opacity: 0
        visibility: hidden


.embed-responsive
  &_style_rounded
    border-radius: 31rem 0 31rem 31rem
    +r($sm)
      border-radius: 20rem 0 20rem 20rem
  &_shadow_default
    box-shadow: 0 7px 20px #eaebeb
