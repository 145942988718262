// Text
$default-font: 'Lexend', sans-serif
$default-font-size: 16rem
$line-height: 1.2

// Colors
$c-text: #7a7a7a
$c-accent: #ffbd3a
$c-accent-hover: #1237a1
$c-gray: #7a7a7a
$c-border: #b2b2b2
$c-red: #e83041
$c-blue: #1237a1
$c-blue2: #1873d3

// Default transition duration
$trs-dur: 0.3s

// Sizes
$rem: 1px
$rem-mobile: 0.75px

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px
$grid-columns: 12

// Media sizes
$xs: 359.98px
$sm: 575.98px
$md: 767.98px
$lg: 991.98px
$xl: 1199.98px
$xxl: 1439.98px

$sm-c: 570px
$md-c: 755px
$lg-c: 970px
$xl-c: 1170px
$xxl-c: 1430px

$grid-breakpoints: (xs: 0, sm: $sm, md: $md, lg: $lg, xl: $xl, xxl: $xxl)
$container-max-widths: (sm: $sm-c, md: $md-c, lg: $lg-c, xl: $xl-c, xxl: $xxl-c)
$gutter: $grid-gutter-width
