html
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  height: 100%
  line-height: $line-height
  font-size: $rem
  --main-header-height: 0px
  +r($md)
    font-size: $rem-mobile


body
  font-size: $default-font-size
  min-height: 100%
  min-width: 320px
  position: relative
  font-family: $default-font
  font-weight: 300
  overflow-x: hidden
  color: $c-text
  display: flex
  flex-direction: column
  // font smoothing
  text-rendering: optimizeLegibility
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  &:not(.show-focus-outlines)
    a,
    button,
    .button,
    [tabindex],
      &:focus
        outline: none
  &:not(.loaded) *
    transition: none !important

+rmin($xxl)
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
    max-width: 1530px

.main
  overflow-x: hidden

.no-scroll
  overflow: hidden

.section
  padding: 60rem 0
  position: relative

::selection
  background-color: $c-accent
  color: #fff

a:hover,
a:focus,
a:active
  text-decoration: none

b, strong
  font-weight: 700

.img-responsive
  display: block
  max-width: 100%
  height: auto
  &.img-width
    width: 100%

.hidden
  display: none !important

.align-left
  text-align: left

.align-center
  text-align: center

.align-right
  text-align: right

.fill-link
  position: absolute
  left: 0
  top: 0
  bottom: 0
  right: 0

.sprite-icon
  display: block
  width: 1em
  height: 1em
  fill: currentColor
  &_outline
    fill: none
    stroke: currentColor

.hr
  background-color: $c-border
  height: 1px
  border: none
  margin: 0

.text-accent
  color: $c-accent

.text-blue
  color: $c-blue

.text-blue-light
  color: $c-blue2

sup
  font-size: 60%

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 125, 150, 200)
$sides: (top, bottom, left, right) 

// that means mt-5: 5px, mt-100: 100px, pl-5: 5px, etc. It goes for margin and padding for each of top, bottom, left, right

@each $space in $spaceamounts
  @each $side in $sides
    .m#{str-slice($side, 0, 1)}-#{$space} 
      margin-#{$side}: #{$space}rem
    
    .p#{str-slice($side, 0, 1)}-#{$space} 
      padding-#{$side}: #{$space}rem