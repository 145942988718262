@font-face
  font-family: 'utopian'
  src:  url('../fonts/utopian.eot?fu05dt')
  src:  url('../fonts/utopian.eot?fu05dt#iefix') format('embedded-opentype'), url('../fonts/utopian.ttf?fu05dt') format('truetype'), url('../fonts/utopian.woff?fu05dt') format('woff'), url('../fonts/utopian.svg?fu05dt#utopian') format('svg')
  font-weight: normal
  font-style: normal
  font-display: block


i
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'utopian' !important
  speak: never
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale


.icon-double-quotes:before
  content: "\e900"

.icon-calendar:before
  content: "\e901"

.icon-search:before
  content: "\e902"

.icon-right-arrow:before
  content: "\e903"

.icon-plus-red-circle:before
  content: "\e904"

.icon-play-button .path1:before
  content: "\e905"
  color: rgb(0, 0, 0)

.icon-play-button .path2:before
  content: "\e923"
  margin-left: -1em

.icon-left-arrow:before
  content: "\e924"

.icon-email:before
  content: "\e925"

.icon-down-arrow:before
  content: "\e926"

.icon-delete-x .path1:before
  content: "\e927"
  color: rgb(3, 3, 3)

.icon-delete-x .path2:before
  content: "\e928"
  margin-left: -1em

.icon-checked .path1:before
  content: "\e929"
  color: rgb(0, 0, 0)

.icon-checked .path2:before
  content: "\e92a"
  margin-left: -1em

.icon-cart-2:before
  content: "\e92b"

.icon-bottom-arrow-circle:before
  content: "\e92c"

.icon-arrow-right:before
  content: "\e92d"

.icon-arrow-left:before
  content: "\e92e"

.icon-linkedin .path1:before
  content: "\e906"

.icon-linkedin .path2:before
  content: "\e907"
  margin-left: -1em

.icon-linkedin .path3:before
  content: "\e908"
  margin-left: -1em

.icon-youtube .path1:before
  content: "\e909"

.icon-youtube .path2:before
  content: "\e90a"
  margin-left: -1em

.icon-twitter .path1:before
  content: "\e90b"

.icon-twitter .path2:before
  content: "\e90c"
  margin-left: -1em

.icon-telegram .path1:before
  content: "\e90d"

.icon-telegram .path2:before
  content: "\e90e"
  margin-left: -1em

.icon-telegram .path3:before
  content: "\e90f"
  margin-left: -1em

.icon-telegram .path4:before
  content: "\e910"
  margin-left: -1em

.icon-telegram .path5:before
  content: "\e911"
  margin-left: -1em
  color: rgb(0, 0, 0)

.icon-telegram .path6:before
  content: "\e912"
  margin-left: -1em

.icon-telegram .path7:before
  content: "\e913"
  margin-left: -1em

.icon-telegram .path8:before
  content: "\e914"
  margin-left: -1em

.icon-telegram .path9:before
  content: "\e915"
  margin-left: -1em

.icon-social .path1:before
  content: "\e916"

.icon-social .path2:before
  content: "\e917"
  margin-left: -1em

.icon-social .path3:before
  content: "\e918"
  margin-left: -1em

.icon-social .path4:before
  content: "\e919"
  margin-left: -1em

.icon-instagram .path1:before
  content: "\e91a"

.icon-instagram .path2:before
  content: "\e91b"
  margin-left: -1em

.icon-instagram .path3:before
  content: "\e91c"
  margin-left: -1em

.icon-instagram .path4:before
  content: "\e91d"
  margin-left: -1em

.icon-facebook:before
  content: "\e91e"

.icon-discord .path1:before
  content: "\e91f"

.icon-discord .path2:before
  content: "\e920"
  margin-left: -1em

.icon-globe:before
  content: "\e921"

.icon-service:before
  content: "\e922"
