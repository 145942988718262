// reset default styles
button
  background: transparent
  border: none
  cursor: pointer
  color: inherit
  text-align: center
  text-decoration: none
  max-width: 100%
  line-height: 1
  padding: 0
  transition: $trs-dur


.button
  display: inline-block
  cursor: pointer
  max-width: 100%
  text-align: center
  transition: $trs-dur
  padding: 10rem 30rem 10rem
  font-family: $default-font
  font-size: 16rem
  font-weight: 700
  line-height: 1.2
  text-decoration: none
  color: #ffffff
  background: $c-accent
  border: 1px solid $c-accent
  border-radius: 4px
  &:hover
    background-color: $c-accent-hover
    border-color: $c-accent-hover
    outline: 0
    color: #fff
  &[disabled]
    background-color: $c-gray
    border-color: $c-gray
    cursor: default
  &--white
    background-color: #fff
    border-color: #fff
    color: $c-accent
  &--blue
    background-color: $c-blue
    border-color: $c-blue
    color: #fff
    &:hover
      background-color: $c-accent
      border-color: $c-accent
  &--type-ghost
    background: transparent
    color: #fff
    border-color: #fff
  &--size-small
    padding: 10px
  &--size-large
    font-size: 20rem
    padding: 17px 40px
  &--hover-white
    &:hover
      color: $c-accent
      background-color: #fff
      border-color: #fff