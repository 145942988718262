=h1-styles
  font-size: 48rem
  font-weight: 700
  line-height: 1.2
  +r($md)
    font-size: 40rem

.h1
  +h1-styles

=h2-styles
  font-size: 40rem
  font-weight: 700
  line-height: 1.2
  +r($md)
    font-size: 35rem
.h2
  +h2-styles

=h3-styles
  font-size: 28rem
  font-weight: 700
  line-height: 1.2
.h3
  +h3-styles

=h4-styles
  font-size: 22rem
  font-weight: 700
  line-height: 1.2
.h4
  +h4-styles

=h5-styles
  font-size: 20rem
  font-weight: 700
  line-height: 1.2
.h5
  +h5-styles

=h6-styles
  font-size: 16rem
  font-weight: 700
  line-height: 1.2
.h6
  +h6-styles

=subtile-styles
  font-size: 22rem
  line-height: 1.5
.subtitle
  +subtile-styles

.section-heading
  margin: 0 auto 40rem
  text-align: center
  max-width: 1000px
  &__subtitle
    margin: 0.5em auto 0

.plaintext
  line-height: 1.5
  h1, h2, h3, h4, h5, h6
    margin-bottom: 1em
  h1
    +h1-styles
  h2
    +h2-styles
  h3
    +h3-styles
  h4
    +h4-styles
  h5
    +h5-styles
  h6
    +h6-styles
  p
    + p
      margin-top: 1.6em
  a
    color: $c-blue
    text-decoration: underline
    &:hover
      color: $c-accent-hover
  ol
    padding-left: 1.2em
  ul
    list-style: none
    &:not([class])
      padding-left: 1.2em
      list-style-type: disc
  > *
    + *
      margin-top: 1.5em
  > * + h1,
  > * + h2,
  > * + h3,
  > * + h4,
  > * + h5,
  > * + h6
    margin-top: 1.6em
  h1 + *,
  h2 + *,
  h3 + *,
  h4 + *,
  h5 + *,
  h6 + *
    margin-top: 0.8em
  > * + ul,
  > * + ol
    margin-top: 1.7em
  > ul + *,
  > ol + *
    margin-top: 1.5em
  li
    + li
      margin-top: 1.2em

// .custom-marker-list
//   li
//     padding-left: 2.5em
//     background-image: url(../img/svg/graphic/list-marker.svg)
//     background-size: 1.6em 1.455em
//     background-repeat: no-repeat
//     background-position: left top 3px


.title-1
  font-size: 70rem
  font-weight: 700
  line-height: 1
  +r($xxl)
    font-size: 56rem
  +r($xl)
    font-size: 50rem
  +r($md)
    font-size: 40rem

.title-2
  font-size: 60rem
  font-weight: 700
  line-height: 1 
  +r($xxl)
    font-size: 50rem
  +r($xl)
    font-size: 45rem
  +r($md)
    font-size: 36rem

.title-3
  font-size: 40rem
  font-weight: 700
  +r($xxl)
    font-size: 36rem
  +r($xl)
    font-size: 34rem
  +r($md)
    font-size: 32rem

.title-4
  font-size: 34rem
  font-weight: 500
  +r($xxl)
    font-size: 30rem
  +r($xl)
    font-size: 28rem

.title-5
  font-size: 25rem
  font-weight: 500
  +r($xxl)
    font-size: 24rem
  +r($xl)
    font-size: 22rem

.text-22
  font-size: 22px

.text-20
  font-size: 20px

.text-18
  font-size: 18px