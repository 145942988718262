.main-footer
  margin-top: auto
  background-color: $c-gray
  color: #fff
  padding-top: 70rem
  &__top
    display: flex
    justify-content: space-between
    +r($lg)
      flex-wrap: wrap
  &__column
    &__title
      font-size: 21rem
      font-weight: 500
      margin-bottom: 25rem
    &-social
      +r($lg)
        width: 100%
        text-align: center
        margin-top: 25rem
        ul
          margin: auto
  &__menu
    list-style-type: none
  &__link
    font-size: 17rem
    line-height: 1.8em
    &:hover
      color: $c-accent
  &__social-menu
    display: flex
    flex-wrap: wrap
    list-style-type: none
    max-width: 240px
    li
      width: 25%
      a
        width: 40px
        height: 40px
        display: block
        margin-bottom: 15rem
        border-radius: 50%
        transition: all .25s linear
        &:hover
          transform: rotate(-25deg)
        i
          font-size: 30px
          color: $c-gray
  &__bottom
    &__menu
      display: flex
      align-items: center
      justify-content: center
      flex-wrap: wrap
      list-style-type: none
      margin-top: 30rem
      a
        padding-left: 15rem
        padding-right: 15rem
  &__copyright
    text-align: center
    margin-top: 25rem
    margin-bottom: 25rem
  