.form-field
  margin-bottom: 25rem

::placeholder
  color: $c-gray
  line-height: normal

.input
  display: block
  width: 100%
  max-width: 100%
  padding: 17rem 20rem
  border: 1px solid $c-border
  border-radius: 0
  background: transparent
  font-size: $default-font-size
  font-weight: 400
  line-height: normal
  text-overflow: ellipsis
  color: $c-text
  caret-color: currentColor
  box-shadow: none
  transition: $trs-dur
  &:focus
    outline: 0
    border-color: $c-accent
  // hide placeholder on focus
  // &:focus::placeholder
  // opacity: 0
  // transition: opacity 0.3s ease
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active  
    transition: background-color 5000s ease-in-out 0s
    -webkit-text-fill-color: currentColor
    caret-color: currentColor
  &_style_line
    border: 2px solid $c-border
    border-width: 0 0 2px
    padding: 8rem 0 6rem
  &.error
    border-color: $c-red

.textarea
  display: block
  resize: none
  width: 100%
  height: 100rem
  max-width: 100%
  padding: 17rem 20rem
  border: 1px solid $c-border
  border-radius: 0
  background: transparent
  font-size: $default-font-size
  font-weight: 400
  line-height: normal
  text-overflow: ellipsis
  color: $c-text
  caret-color: currentColor
  box-shadow: none
  transition: $trs-dur
  &.error
    border-color: $c-red
  &:focus
    outline: 0
    border-color: $c-accent
  // hide placeholder on focus
  // &:focus::placeholder
  // opacity: 0
  // transition: opacity 0.3s ease

.select-wrap
  display: block
  // overflow: hidden
  // padding: 0
  // margin: 0
  cursor: default
  position: relative
  color: $c-text
  z-index: 1

.select
  display: block
  width: 100%
  font-size: $default-font-size
  line-height: 1.25
  padding: 17rem 70rem 17rem 20rem
  box-shadow: none
  appearance: none
  background-color: transparent
  background-image: url(../img/svg/graphic/arrow-down-s-fill_gray.svg)
  background-repeat: no-repeat
  background-position: calc(100% - 14px) 50%
  border: 1px solid $c-border
  border-radius: 0
  cursor: pointer
  color: $c-text
  text-overflow: ellipsis
  transition: 0.3s
  &.empty
    color: $c-gray
  &.error
    border-color: $c-red
  option
    color: $c-text
  &:focus
    outline: 0
    border-color: $c-accent
    background-image: url(../img/svg/graphic/arrow-down-s-fill.svg)
  &::-moz-focusring
    color: transparent
    text-shadow: 0 0 0 #000
    outline: 0
  &::-ms-expand
    display: none
  &:disabled
    background-color: $c-gray
  &_size_small
    background-position: calc(100% - 14px) 50%
    padding-right: 50px
    padding-top: 11rem
    padding-bottom: 11rem
  &_style_line
    border-width: 1px
    border: 2px solid $c-border
    border-width: 0 0 2px
    padding: 8rem 50rem 6rem 0
    background-position: calc(100% - 11rem) 50%

.form-box
  margin-bottom: -42rem
  text-align: left

.form-col
  margin-bottom: 42rem

.field
  display: block
  position: relative
  &__label
    display: block

// .checkbox
//   cursor: pointer
//   position: relative
//   color: $c-text
//   font-size: 16px
//   transition: 0.3s
//   line-height: 1.4
//   user-select: none
//   +r($md)
//     font-size: 14px
//   &__input
//     top: 14px
//     left: 14px
//     &:checked + .checkbox__content
//       &::after
//         opacity: 1
//         visibility: visible
//   &__content
//     padding-left: 35px
//     padding-top: 1px
//     min-height: 23px
//     display: inline-block
//     position: relative
//     vertical-align: middle
//     +r($md)
//       padding-top: 3px
//     a
//       color: $c-accent
//       text-decoration: underline
//       &:hover, &:focus
//         color: $c-accent-hover
//     &::before
//       content: ""
//       position: absolute
//       left: 0
//       top: 0
//       width: 23px
//       height: 23px
//       border-radius: 4px
//       border: 1px solid #c3c2c2
//       background-color: #ffffff
//       background-position: 50%
//       background-size: auto 50%
//       transition: 0.3s
//     &::after
//       content: ""
//       background-image: url(../img/svg/graphic/check.svg)
//       background-size: contain
//       background-repeat: no-repeat
//       position: absolute
//       left: 4px
//       top: 6px
//       width: 16px
//       height: 16px
//       transition: 0.3s
//       opacity: 0
//       visibility: hidden
