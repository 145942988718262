.cover-pic, .contain-pic
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.cover-pic
  object-fit: cover

.contain-pic
  object-fit: contain
