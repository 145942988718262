.breadcrumb
  font-size: 16px
  &__list
    display: flex
    flex-wrap: wrap
    align-items: center
    margin-bottom: -20px
  &__item
    padding-right: 30px
    position: relative
    margin-bottom: 20px
    &::after
      content: "/"
      position: absolute
      top: 0
      left: calc(100% - 17px)
    &:last-child
      padding-right: 0
      font-weight: 700
      &::after
        display: none
  &__link
    &:hover
      color: $c-accent
  