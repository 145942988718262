// html
//   --main-header-height: 174px
//   +r($xl)
//     --main-header-height: 110px
//   +r($md)
//     --main-header-height: 64px

// .main-header-outer
//   min-height: var(--main-header-height)

body
  padding-top: 160rem
  +r($xl)
    padding-top: 120rem
  +r($lg)
    padding-top: 50px
  &.is-scrolled
    padding-top: 100rem
    .main-header
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075)
      height: 100rem
      +r($lg)
        height: 50px
      .main-header
        &__logo-img
          width: 250px
          
          // Edge (legacy) flickering fix
          @supports (-ms-ime-align:auto)
            transition: none

.main-header
  position: fixed
  backface-visibility: hidden
  z-index: 1000
  left: 0
  top: 0
  width: 100%
  background-color: #ffffff
  transition-property: box-shadow
  height: 160rem
  display: flex
  align-items: center
  transition: all .25s linear
  +r($xl)
    height: 120rem
  +r($lg)
    height: 50px
  &__content
    display: flex
    align-items: center
  &__logo,
  &__logo-img
    width: 310px
    transition: all .25s linear
    +r($xxl)
      width: 280px
    +r($xl)
      width: 180px
  &__menu
    margin-left: auto
  &__menu-opener
    margin-left: auto
    +rmin($lg)
      display: none
  
    